import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [5],
		"/(main)/analytics": [6],
		"/api/chat": [~26],
		"/(main)/artists": [~7],
		"/(main)/brain": [~8,[2]],
		"/(main)/brain/chat/[id]": [~9,[2]],
		"/(main)/create": [10],
		"/(main)/customers": [11],
		"/(main)/dashboard": [~12],
		"/(main)/music": [13],
		"/(main)/orders": [14],
		"/(main)/pricing": [15],
		"/(main)/products": [16],
		"/(main)/profile2": [22],
		"/(main)/profile": [~17,[3]],
		"/(main)/profile/account": [~18,[3]],
		"/(main)/profile/appearance": [~19,[3]],
		"/(main)/profile/display": [~20,[3]],
		"/(main)/profile/notifications": [~21,[3]],
		"/sign-in": [27],
		"/sign-up": [28],
		"/(website)/[slug]": [23,[4]],
		"/(website)/[slug]/about": [24,[4]],
		"/(website)/[slug]/music": [25,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';